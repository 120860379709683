@import "variables";

.text-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.text-lines-2 {
  @extend .text-lines;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
}

.text-lines-3 {
  @extend .text-lines;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
}

.text-link {
  cursor: pointer;
}

.text-shadow {
  text-shadow: 0 5px 8px black;
}

.page-body a:not(.btn) {
  color: $primary !important;
}

.page-section {
  &.bg-dark, .bg-dark {
    a:not(.btn) {
      font-weight: 600;
      color: white !important;

      i {
        color: #111 !important;
      }
    }
  }
}

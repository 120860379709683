.btn-icon {
  display: inline-flex;
  width: 35px;
  height: 35px;
  position: relative;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  opacity: .9;
  overflow: visible;

  &:hover {
    text-decoration: none !important;
  }

  &.btn-icon-anim, .gallery-block-item:hover & {
    animation: scroll-down-anim 1.15s infinite;
  }

  &:before {
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
    transition: all 500ms cubic-bezier(0.250, 0.460, 0.450, 0.940);
    z-index: 1;
  }

  i {
    z-index: 2;
    font-size: 0.8rem;
  }
}



@import "~bootstrap/scss/mixins";

.carousel-block {
  .carousel-content {
    font-size: calc(0.3rem + 1vw);
    margin-bottom: 20px;
  }

  @include media-breakpoint-up(md) {
    .carousel-content {
      font-size: calc(0.4rem + 1vw);
      margin-bottom: 60px;
    }
  }

  @include  media-breakpoint-only(sm) {
    .carousel-content {
      margin-bottom: 40px;
    }
  }

  @include media-breakpoint-down(sm) {
    .owl-pagination {
      bottom: 4px !important;
    }
    .owl-page span {
      width: 4px;
      height: 4px;
    }
  }
}


.animations-scale-preserver {
  // The preserver starts from the reference image size, then we scale it according to the image aspect ratio, it's like scaling an image of elements
  transform-origin: center;
  position: absolute;
  left: 50%;
  top: 50%;
}

.hacker-text {
  position: absolute;
  font-size: 25px;
  color: white;
}

.arc {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: 1px dashed white;
  transform: scaleZ(2);
}

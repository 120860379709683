
.animated-sitemap-location {
  position: absolute;
  left: 50%;
  top: 50%;
}

.animated-site-location-text {
  font-family: 'Space Mono', monospace;
  color: white;
  position: absolute;
  opacity: 0;
  width: 140px;
}

.animated-site-location-dot {
  position: absolute;
  background: white;
  width: 8px;
  height: 8px;
  top: 0;
  left: 0;
  opacity: 0;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}

.animated-site-location-line-one,
.animated-site-location-line-two {
  position: absolute;
  top: 0;
  left: 0;
  background: white;
  opacity: 0;
}

.animated-site-location-line-one {
  width: 1px;
}

.animated-site-location-line-two {
  height: 1px;
}

.scale-enter-active,
.scale-leave-active {
  transition: all 2s ease;
}
.scale-enter-from,
.scale-leave-to {
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
}
.scale-enter-to,
.scale-leave-from {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
}

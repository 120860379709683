
.image-list {
  margin-bottom: 20px;
  padding: 0 16px;
}
.image-list .picrow .item {
  float: left;
  position: relative;
  overflow: hidden;
}
.image-list .picrow > a .potw {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  font-size: 20px;
  line-height: 20px;
  color: #646464;
  background-color: rgba(0, 0, 0, 0.6);
  width: 40px;
  height: 40px;
}
.image-list .picrow > a > .title {
  position: absolute;
  display: block;
  opacity: 0;
  font-size: 18px;
  padding: 15px;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
}
.image-list .picrow > a:hover {
  outline: 2px solid #3f9efa;
}
.image-list .picrow > a:hover .potw {
  color: #f1b401;
}
.image-list .picrow > a:hover .title {
  opacity: 1;
  filter: alpha(opacity=100);
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.image-list.image-list-150 > a > .title {
  font-size: 16px;
}

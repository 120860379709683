
.dashed-arc {
  position: absolute;
}

.dashed-arc-line {
  position: absolute;
  left: 0;
  top: 0;
  background: white;
  height: 1px;
  transition: all 2s ease-in-out;
  transform-origin: center;
  backface-visibility: hidden;
}

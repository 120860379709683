.block-gallery_block {
  .gallery-block-item {
    overflow: hidden;

    h2 {
      max-width: 70%;
    }

    h2, img, .btn-icon {
      transition: all 1s ease-in-out;
    }

    &:hover {
      img {
        transform: scale(1.2);
      }

      h2, .btn-icon {
        transform: scale(1.05);
      }
    }
  }
}

// Show subitems on hover and on mobile menu
.offcanvas.show .dropdown-menu,
.dropdown:hover > .dropdown-menu {
  display: block;
  margin-top: 0; /* remove the gap so it doesn't close */
}

.nav-link {
  color: white !important;
  font-size: 1rem;

  &.active {
    font-weight: bold;
  }
}

.third-level-menu {
  display: none;
  position: absolute;
  top: 0;
  left: 100%;
}

.offcanvas.show .third-level-menu {
  margin-left: 1rem;
  border: none;
}


#header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: transparent;
  border-bottom: 1px solid #ffffff40;

  &,
  & > div {
    transition: background-color 0.6s;
  }

  .nav-scroller {
    position: relative;
    z-index: 4;
    overflow: visible;

    .nav {
      display: flex;
      flex-wrap: nowrap;
      padding-bottom: 1rem;
      margin-top: -1px;
      overflow-x: auto;
      text-align: center;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
    }
  }

  .dropdown-item:hover {
    text-decoration: none;
  }
}
